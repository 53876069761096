import { useState, useEffect } from "react";
import * as BsIcons from "react-icons/bs";
import React from "react";
import "./Cotizacion.css";
import { NavLink, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import Cobertura from "../../components/Cobertura/Cobertura";
import CoberturaMovil from "../../components/CoberturaMovil/CoberturaMovil";
import Accordion from "react-bootstrap/Accordion";
import ButtonWhatsapp from "../../components/ButtonWhatsapp/ButtonWhatsapp";
const Cotizacion = () => {
  const urls = window.location.href;
  const id = urls.split("/").pop();
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(id), "clave_secreta");
  const datas = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  const [data, setData] = useState(null);

  useEffect(() => {
    if (datas) {
      setData(datas.datos);
    }
  }, []);

  const formatoMoneda = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const formatoPesosArgentinos = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
  });
 const calculoRow =()=> {
	const ancho = (data.planes).length;
	const tamano = 85/ancho;
	
	return tamano
 }
 
 
  return (
    <div className="cotizacion">
      {data === null && <p>Cargando...</p>}
      {data && (
        <div className="container mt-5">
          <ButtonWhatsapp info={{telefonoWhatsapp: 261454617, auto: data}}/>
          <div className="text-start">
            <NavLink exact to="/seguro-auto">
              <h1>
                <BsIcons.BsArrowLeft />
              </h1>
            </NavLink>
          </div>
          <div className="row text-start">
            <h1>Podemos ofrecerte los siguinetes planes de cobertura</h1>
            <h3>DATOS DE LA COTIZACÍON</h3>
            <p>
              <strong>Cotización Nº</strong> {data.nroPresupuesto}
            </p>
            <p>
              <strong>
                {data.marca} {data.version} {data.anio}
              </strong>
              <br />
              VALOR ASEGURADO:{" "}
              <strong>{formatoPesosArgentinos.format(data.valor)}</strong>
            </p>
            {/* Aquí puedes seguir mostrando el resto de la información */}
            {/* Por ejemplo, puedes mapear los planes y mostrar sus detalles */}

			<div className="container mb-5 d-none d-sm-none d-md-block" style={{display:'fixed'}}>
				<div style={{display:'flex', justifyContent:'center'}}>					
					<div style={{width:'15%',fontSize:'0.7rem'}}>
						<li className="list-group-item" style={{display:'flex', justifyContent:'center', background:'#213450', color:'#fff',  border: '1px solid #fff', height: '170px', alignItems:'center'}}><strong>Cobertura</strong> </li>
						<li className="list-group-item" style={{padding:'5px'}}><strong>Responsabilidad Civil:</strong> </li>
						<li className="list-group-item" style={{padding:'5px', background:'#e9e9e9'}}><strong>Robo Hurto Total:</strong></li>
						<li className="list-group-item" style={{padding:'5px'}}><strong>Robo Hurto Parcial:</strong></li>          
						<li className="list-group-item" style={{padding:'5px', background:'#e9e9e9'}}><strong>Incendio Total:</strong> </li>
						<li className="list-group-item" style={{padding:'5px'}}><strong>Destruccion Total:</strong> </li>
						<li className="list-group-item" style={{padding:'5px', background:'#e9e9e9'}}><strong>Cristales Laterales:</strong> </li>
						<li className="list-group-item" style={{padding:'5px'}}><strong>Daño Parcial amparo Robo Total:</strong></li>
						<li className="list-group-item" style={{padding:'5px', background:'#e9e9e9'}}><strong>Clausula Destruccion Total:</strong></li>
						<li className="list-group-item" style={{padding:'5px'}}><strong>Ajuste Automatico Suma:</strong></li>
						<li className="list-group-item" style={{padding:'5px', background:'#e9e9e9'}}><strong>Incendio Parcial:</strong> </li>
						<li className="list-group-item" style={{padding:'5px'}}><strong>Granizo:</strong> </li>
						<li className="list-group-item" style={{padding:'5px', background:'#e9e9e9'}}><strong>Luneta Parabrisa:</strong> </li>
						<li className="list-group-item" style={{padding:'5px'}}><strong>Daño Parcial Accidente:</strong> </li>
						<li className="list-group-item" style={{padding:'5px', background:'#e9e9e9'}}><strong>Otros Daños Parciales:</strong> </li>
						<li className="list-group-item" style={{padding:'5px'}}><strong>Reposición 0Km:</strong></li>          
						<li className="list-group-item" style={{padding:'5px', background:'#e9e9e9'}}><strong>Auto Sustituto</strong> </li>
						<li className="list-group-item" style={{padding:'5px'}}><strong>Remolque:</strong></li>
						<li className="list-group-item" style={{padding:'5px', background:'#e9e9e9'}}><strong>Seguro Muerte Conductor:</strong> </li>
						<li className="list-group-item" style={{padding:'5px'}}> </li>
					</div>
					{data.planes.map((plan, index) => (
              <div key={index} style={{width: data && calculoRow()+'%',fontSize:'0.7rem'}}>
                                 <li className="list-group-item text-center" style={{height: '170px', background:'#213450', color:'#fff', border: '1px solid #fff',paddingTop:'10px'}}>
									<div style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'space-between',height:'100%'}}>
									<div>{plan.descripcion}</div><div style={{fontSize: '1rem'}}>{formatoMoneda.format(plan.debito)}</div>  </div></li>
      
                      <Cobertura data={plan.coberturas} style={{color:'black'}}/>
                     <li className="list-group-item text-center" style={{padding:'5px'}}><NavLink
                        exact
                        to={
                          "/emision-cotizacion/" +
                          encodeURIComponent(
                            CryptoJS.AES.encrypt(
                              JSON.stringify({ plan, data }),
                              "clave_secreta"
                            ).toString()
                          )
                        }
                      >
                        <div style={{
							background:'#17a0d5',
							color: '#fff',
							padding:'5px'
						}}>Continuar</div>
                      </NavLink></li>
                    </div>
         
            ))}
				</div>

			</div>
      <div className="d-md-none col-sm-12 mb-3" >
        PLANES DISPONIBLES:
      </div>
            {data.planes.map((plan, index) => (
              <div className="d-md-none col-sm-12 mb-3" key={index}>
                <Accordion>
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header ><div><h5 className="card-title">{plan.descripcion}</h5><br/>VALOR <strong>{formatoMoneda.format(plan.prima)}</strong></div> </Accordion.Header>
                        <Accordion.Body>


                      <CoberturaMovil data={plan.coberturas} />
                      <NavLink
                        exact
                        to={
                          "/emision-cotizacion/" +
                          encodeURIComponent(
                            CryptoJS.AES.encrypt(
                              JSON.stringify({ plan, data }),
                              "clave_secreta"
                            ).toString()
                          )
                        }
                      >
                        <button className="btn-contact-auto">Continuar</button>
                      </NavLink>                  
                </Accordion.Body>
                </Accordion.Item>
                </Accordion>
              </div>
            ))}
            <div className="container mb-5">
              <ul style={{ listStyleType: 'none', fontSize:'0.8rem' }}>
                <li>(1) Responsabilidad civil en la República Argentina y países limítrofes indemnizatorio hasta la suma de $80.000.000 para uso particular.</li>
                <li>(2) Franquicia a cargo del asegurado equivalente al porcentaje seleccionado calculado sobre el valor de la Suma Asegurada</li>
                <li>(3) Hasta $800.000 por evento, sin franquicia.</li>
                <li>(4) Sin límite, sin franquicia.</li>
                <li>(5) Daños a consecuencia de huelga y/o tumulto, alboroto popular, inundacíon, desbordamiento, terremoto y granizo.</li>
                <li>(6) Franquicia a cargo del asegurado equivalente al monto de la franquicia.</li>
                <li>No se aplica descuentos sobre los planes GARAGE, B1 Y B.</li>
                <li>* Los precios suministrados de los planes correponden a pago con tarjeta.</li>
              </ul>
      
              <div className="container">
              <div style={{padding: '15px'}} ><h5>Servicios al cliente</h5></div>
              <ul style={{ fontSize:'0.8rem' }}>
                <li>Asesoramiento legal de urgencia las 24 hrs, en caso de accidente con daño físico y/o muerte de terceros.</li>
                <li>Mecánica ligera</li>
                <li> Centro de Atencíon a terceros de siniestros las 24hs.</li>
              </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cotizacion;
